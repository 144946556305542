import React from 'react';
import {Button, Modal} from 'antd';
import CreateStoreForm from './CreateStoreForm';
import {StoreForm} from '../../ts/store';
import {toast} from 'react-toastify';
import {useCreateStore} from '../../hooks/api/store';
import {handleStoreError} from '../../utils/errors/store.errors';

interface CreateStoreModalProps {
  isOpen: boolean;
  handleClose: () => void;
  handleSuccessCreate: () => Promise<void>;
}

const CreateStoreModal = ({
  isOpen,
  handleClose,
  handleSuccessCreate,
}: CreateStoreModalProps): JSX.Element => {
  const {mutate} = useCreateStore();

  const handleSubmit = (data: StoreForm) => {
    mutate(data, {
      onSuccess: async (response: boolean) => {
        if (response) {
          toast.success('Successfully created Store');
          await handleSuccessCreate();
          handleClose();
        }
      },
      onError: error => {
        handleStoreError(error as string);
      },
    });
  };

  return (
    <Modal
      title="Create new Store with Manager and HQ users"
      okText={'Create'}
      destroyOnClose={true}
      open={isOpen}
      onCancel={() => handleClose()}
      footer={null}>
      <CreateStoreForm handleSubmit={handleSubmit}>
        <div className={'d-flex jc-end gap-16'}>
          <Button className={'pl-28 pr-28'} onClick={() => handleClose()}>
            Cancel
          </Button>
          <Button
            className={'pl-28 pr-28'}
            type={'primary'}
            htmlType={'submit'}>
            Create
          </Button>
        </div>
      </CreateStoreForm>
    </Modal>
  );
};

export default CreateStoreModal;
