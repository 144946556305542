import axios, {AxiosError, AxiosInstance, AxiosResponse} from 'axios';
import {ErrorResponse} from '../ts/global-types';
import {TOKEN} from '../utils/constants/storage';

const request: AxiosInstance = axios.create({
  baseURL: process.env.REACT_APP_BASE_API_URL ?? '',
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
});

request.interceptors.request.use(config => {
  const token = localStorage.getItem(TOKEN);
  if (token) {
    config.headers['Authorization'] = `Bearer ${token}`;
  }

  return config;
});

export const apiRequest = async <T>(request: Promise<any>): Promise<T> => {
  const onSuccess = (response: AxiosResponse<T>) => response?.data;
  const onError = (error: AxiosError<ErrorResponse>) => {
    throw error?.response?.data.message;
  };

  return request.then(onSuccess).catch(onError);
};

export default request;
