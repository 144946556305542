import React from 'react';
import {Button, Form, Input} from 'antd';
import logo from '../assets/logo.png';
import {CurrentUser, UserInfo} from '../ts/user';
import {useSignIn} from '../hooks/api/auth';
import {TOKEN} from '../utils/constants/storage';
import {useNavigate} from 'react-router-dom';
import {handleLoginError} from '../utils/errors/auth.errors';

const LoginPage = (): JSX.Element => {
  const {mutate} = useSignIn();
  const navigate = useNavigate();

  const handleSubmit = (data: UserInfo): void => {
    mutate(data, {
      onSuccess: (response: CurrentUser) => {
        localStorage.setItem(TOKEN, response?.token);
        navigate('/');
      },
      onError: error => {
        handleLoginError(error as string);
      },
    });
  };

  return (
    <>
      <div className={'d-flex flex-1 jc-center ai-center'}>
        <div className={'mb-20'}>
          <img
            src={logo}
            className={'mb-48'}
            alt={'Cell logo'}
            width={'350'}
            height={'100'}
          />
          <Form onFinish={handleSubmit}>
            <Form.Item
              name={'username'}
              rules={[{required: true, whitespace: true}, {min: 3}, {max: 20}]}>
              <Input placeholder={'Username'} size={'large'} />
            </Form.Item>
            <Form.Item
              name={'password'}
              rules={[{required: true, whitespace: true}, {min: 8}]}>
              <Input.Password placeholder={'Password'} size={'large'} />
            </Form.Item>
            <Button
              className={'w-100 mt-4'}
              type={'primary'}
              htmlType={'submit'}>
              Sign In
            </Button>
          </Form>
        </div>
      </div>
    </>
  );
};

export default LoginPage;
