import React from 'react';
import LoginPage from './pages/LoginPage';
import {Navigate, Outlet, Route, Routes} from 'react-router-dom';
import {TOKEN} from './utils/constants/storage';
import HomePage from './pages/HomePage';

const ProtectedRoute = () => {
  const token = localStorage.getItem(TOKEN);
  if (!token) return <Navigate to={'/login'} replace />;

  return <Outlet />;
};

const App = (): JSX.Element => {
  return (
    <>
      <Routes>
        <Route path={'/'} element={<ProtectedRoute />}>
          <Route index path={'/'} element={<HomePage />} />
        </Route>
        <Route
          path={'/login'}
          element={
            localStorage.getItem(TOKEN) ? <Navigate to="/" /> : <LoginPage />
          }
        />
      </Routes>
    </>
  );
};

export default App;
