import {Errors} from '../constants/errors';
import {toast} from 'react-toastify';

export const handleStoreError = (error: string): void => {
  if (error === Errors.StoreNameUnique) {
    toast.error('Store name is already taken');
    return;
  }
  if (error === Errors.UserManagerUsernameUnique) {
    toast.error('Manager username is already taken');
    return;
  }
  if (error === Errors.UserHQUsernameUnique) {
    toast.error('HQ username is already taken');
  }
};
