import React from 'react';
import {CheckCircleTwoTone, CloseCircleTwoTone} from '@ant-design/icons';
import {ColumnsType} from 'antd/es/table';
import {StoreWithUsers} from '../ts/user';
import {Button} from 'antd';

export const StoresTableColumns = (
  handleUpdateActivation: (storeWithUsers: StoreWithUsers) => void,
): ColumnsType<StoreWithUsers> => [
  {
    title: 'No',
    dataIndex: 'no',
    key: 'no',
    width: 50,
    align: 'center',
    render: (value, record, index) => index + 1,
  },
  {
    title: 'Store name',
    dataIndex: 'storeName',
    key: 'storeName',
    ellipsis: true
  },
  {
    title: 'Manager App',
    dataIndex: 'managerApp',
    key: 'managerApp',
  },
  {
    title: 'HQ App',
    dataIndex: 'hqApp',
    key: 'hqApp',
  },
  {
    title: 'Active',
    dataIndex: 'status',
    key: 'status',
    align: 'center',
    width: 80,
    render: (value, record) => {
      return record.storeActive ? (
        <CheckCircleTwoTone style={{fontSize: '22px'}} twoToneColor="#52c41a" />
      ) : (
        <CloseCircleTwoTone style={{fontSize: '22px'}} twoToneColor="#ff4d4f" />
      );
    },
  },
  {
    title: 'Action',
    dataIndex: 'action',
    key: 'action',
    align: 'center',
    render: (value, record) => {
      return (
        <Button onClick={() => handleUpdateActivation(record)}>
          {record.storeActive ? 'Deactivate' : 'Activate'}
        </Button>
      );
    },
  },
];
