import React from 'react';
import {Divider, Form, Input} from 'antd';
import {StoreForm} from '../../ts/store';

type CreateStoreFormProps = {
  children?: React.ReactNode;
  handleSubmit: (data: StoreForm) => void;
};

const CreateStoreForm = ({
  children,
  handleSubmit,
}: CreateStoreFormProps): JSX.Element => {
  return (
    <Form layout="vertical" className={'mt-16'} onFinish={handleSubmit}>
      <Form.Item
        labelAlign={'right'}
        name={'storeName'}
        rules={[
          {
            required: true,
            whitespace: true,
            message: 'Please enter store name',
          },
        ]}>
        <Input placeholder={'Store name'} size={'large'} />
      </Form.Item>
      <Divider className={'mb-16'} />
      <div>
        <h3 className={'mb-8'}>Manager</h3>
        <Form.Item
          name={'managerUsername'}
          dependencies={['hqUsername']}
          rules={[
            ({getFieldValue}) => ({
              validator(_, value: string) {
                if (!value || getFieldValue('hqUsername') !== value) {
                  return Promise.resolve();
                }
                return Promise.reject(
                  new Error('User names must be unique'),
                );
              },
            }),
            {
              required: true,
              whitespace: true,
              message: 'Manager username is required',
            },
            
            {
              min: 3,
              message: 'Manager username must be at least 3 characters',
            },
            {
              max: 20,
              message: 'Manager username must be up to 20 characters',
            },
          ]}>
          <Input placeholder={'Username'} size={'large'} />
        </Form.Item>
        <Form.Item
          name={'managerPassword'}
          rules={[
            {
              required: true,
              whitespace: true,
              message: 'Manager password is required',
            },
            {
              min: 8,
              message: 'Manager password must be at least 8 characters',
            },
          ]}>
          <Input.Password placeholder={'Password'} size={'large'} />
        </Form.Item>
        <Form.Item
          name={'managerConfirmPassword'}
          dependencies={['managerPassword']}
          rules={[
            {
              required: true,
              whitespace: true,
              message: 'Manager confirm password is required',
            },
            {
              min: 8,
              message: 'Manager confirm password must be at least 8 characters',
            },
            ({getFieldValue}) => ({
              validator(_, value: string) {
                if (!value || getFieldValue('managerPassword') === value) {
                  return Promise.resolve();
                }
                return Promise.reject(
                  new Error('Manager passwords that you entered do not match'),
                );
              },
            }),
          ]}>
          <Input.Password placeholder={'Confirm password'} size={'large'} />
        </Form.Item>
      </div>
      <Divider className={'mb-16'} />
      <div>
        <h3 className={'mb-8'}>HQ</h3>
        <Form.Item
          name={'hqUsername'}
          dependencies={['managerUsername']}
          rules={[
            ({getFieldValue}) => ({
              validator(_, value: string) {
                if (!value || getFieldValue('managerUsername') !== value) {
                  return Promise.resolve();
                }
                return Promise.reject(
                  new Error('User names must be unique'),
                );
              },
            }),
            {
              required: true,
              whitespace: true,
              message: 'HQ username is required',
            },
            {min: 3, message: 'HQ username must be at least 3 characters'},
            {max: 20, message: 'HQ username must be up to 20 characters'},
          ]}>
          <Input placeholder={'Username'} size={'large'} />
        </Form.Item>
        <Form.Item
          name={'hqPassword'}
          rules={[
            {
              required: true,
              whitespace: true,
              message: 'HQ password is required',
            },
            {
              min: 8,
              message: 'HQ password must be at least 8 characters',
            },
          ]}>
          <Input.Password placeholder={'Password'} size={'large'} />
        </Form.Item>
        <Form.Item
          name={'hqConfirmPassword'}
          dependencies={['hqPassword']}
          rules={[
            {
              required: true,
              whitespace: true,
              message: 'HQ confirm password is required',
            },
            {
              min: 8,
              message: 'HQ confirm password must be at least 8 characters',
            },
            ({getFieldValue}) => ({
              validator(_, value: string) {
                if (!value || getFieldValue('hqPassword') === value) {
                  return Promise.resolve();
                }
                return Promise.reject(
                  new Error('HQ passwords that you entered do not match'),
                );
              },
            }),
          ]}>
          <Input.Password placeholder={'Confirm password'} size={'large'} />
        </Form.Item>
      </div>
      {children}
    </Form>
  );
};

export default CreateStoreForm;
