import React from 'react';
import {Skeleton, SkeletonProps, Table} from 'antd';
import {ColumnsType} from 'antd/lib/table';

export type SkeletonTableColumnsType = {
  key: string;
};

type SkeletonTableProps = SkeletonProps & {
  columns: ColumnsType<SkeletonTableColumnsType>;
  rowCount?: number;
};

const SkeletonTable = ({
  loading = false,
  active = true,
  rowCount = 6,
  columns,
  children,
  className,
}: SkeletonTableProps): JSX.Element => {
  return loading ? (
    <Table
      rowKey="key"
      pagination={false}
      dataSource={[...Array(rowCount)].map((_, index) => ({
        key: `key${index}`,
      }))}
      columns={columns.map(column => {
        return {
          ...column,
          render: () => (
            <Skeleton
              key={column.key}
              title
              active={active}
              paragraph={false}
              className={className}
            />
          ),
        };
      })}
    />
  ) : (
    <>{children}</>
  );
};

export default SkeletonTable;
