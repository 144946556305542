import React from 'react';
import Layout, {Content, Header} from 'antd/es/layout/layout';
import {Button} from 'antd';
import {TOKEN} from '../utils/constants/storage';
import StoresPage from './StoresPage';

function HomePage(): JSX.Element {
  const onLogout = () => {
    localStorage.removeItem(TOKEN);
    window.location.reload();
  };

  return (
    <Layout>
      <Header className={'d-flex jc-end ai-center'}>
        <Button onClick={onLogout}>Logout</Button>
      </Header>
      <Content>
        <StoresPage />
      </Content>
    </Layout>
  );
}

export default HomePage;
