import React from 'react';
import ReactDOM from 'react-dom/client';
import './styles/main.scss';
import App from './App';
import {QueryClient, QueryClientProvider} from '@tanstack/react-query';
import {ConfigProvider} from 'antd';
import {BrowserRouter} from 'react-router-dom';
import {ToastContainer} from 'react-toastify';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

const defaultTheme = {
  token: {
    colorPrimary: '#0090FF',
  },
};

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);
root.render(
  <QueryClientProvider client={queryClient}>
    <ConfigProvider theme={defaultTheme}>
      <BrowserRouter>
        <ToastContainer autoClose={3000} style={{fontSize: 14}} />
        <App />
      </BrowserRouter>
    </ConfigProvider>
  </QueryClientProvider>,
);
