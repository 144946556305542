import request, {apiRequest} from '../../services/request';
import {useMutation} from '@tanstack/react-query';
import {API_AUTH_LOGIN} from '../../utils/constants/endpoints';
import {CurrentUser, UserInfo} from '../../ts/user';

export const useSignIn = () => {
  return useMutation(['sign-in'], {
    mutationFn: async (data: UserInfo): Promise<CurrentUser> => {
      return await apiRequest<CurrentUser>(request.post(API_AUTH_LOGIN, data));
    },
  });
};
