import React from 'react';
import {Modal} from 'antd';
import {StoreWithUsers} from '../ts/user';
import {useStoreActivation} from '../hooks/api/store';
import {toast} from 'react-toastify';
import {StoreActivation} from '../ts/store';

type StoreActivationModalProps = {
  store?: StoreWithUsers;
  isOpen: boolean;
  handleClose: () => void;
  handleSuccessActivation: () => Promise<void>;
};

const StoreActivationModal = ({
  store,
  isOpen,
  handleClose,
  handleSuccessActivation,
}: StoreActivationModalProps): JSX.Element => {
  const {mutate} = useStoreActivation();

  const handleSubmit = () => {
    const storeActivation: StoreActivation = {
      id: store!.storeId,
      activate: !store!.storeActive,
    };
    mutate(storeActivation, {
      onSuccess: async (response: boolean) => {
        if (response) {
          toast.success(
            `Successfully ${store?.storeActive ? 'deactivated' : 'activated'} ${
              store?.storeName
            }`,
          );
          await handleSuccessActivation();
          handleClose();
        }
      },
    });
  };

  return (
    <Modal
      title={`Are you sure you want to ${
        store?.storeActive ? 'deactivate' : 'activate'
      } ${store?.storeName}?`}
      okText={'Submit'}
      destroyOnClose={true}
      open={isOpen}
      onCancel={handleClose}
      onOk={handleSubmit}
    />
  );
};

export default StoreActivationModal;
