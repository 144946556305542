import {useMutation, useQuery} from '@tanstack/react-query';
import request, {apiRequest} from '../../services/request';
import {API_STORES} from '../../utils/constants/endpoints';
import {StoreWithUsers} from '../../ts/user';
import {StoreActivation, StoreForm} from '../../ts/store';

export const useStores = () => {
  const {data, refetch, isLoading} = useQuery<StoreWithUsers[]>(
    ['stores'],
    async () => await apiRequest<StoreWithUsers[]>(request.get(API_STORES)),
  );

  return {data, refetch, isLoading};
};

export const useCreateStore = () => {
  return useMutation(['create-store'], {
    mutationFn: async (data: StoreForm): Promise<boolean> => {
      return await apiRequest<boolean>(request.post(API_STORES, data));
    },
  });
};

export const useStoreActivation = () => {
  return useMutation(['store-activation'], {
    mutationFn: async (storeActivation: StoreActivation): Promise<boolean> => {
      return await apiRequest<boolean>(
        request.patch(`${API_STORES}/activation`, storeActivation),
      );
    },
  });
};
