import React, {useState} from 'react';
import {Button, Table} from 'antd';
import {StoresTableColumns} from '../utils/stores-columns';
import {useStores} from '../hooks/api/store';
import SkeletonTable, {
  SkeletonTableColumnsType,
} from '../components/SkeletonTable';
import CreateStoreModal from '../components/CreateStore/CreateStoreModal';
import {StoreWithUsers} from '../ts/user';
import StoreActivationModal from '../components/StoreActivationModal';

const StoresPage = (): JSX.Element => {
  const {data, refetch, isLoading} = useStores();
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const [isActivationModalOpen, setIsActivationModalOpen] = useState(false);
  const [selectedStore, setSelectedStore] = useState<StoreWithUsers>();

  const refetchStores = async (): Promise<void> => {
    await refetch();
  };

  const handleUpdateActivation = async (
    store: StoreWithUsers,
  ): Promise<void> => {
    setIsActivationModalOpen(true);
    setSelectedStore(store);
  };

  return (
    <div className={'d-flex flex-column p-40'}>
      <div className={'d-flex jc-between ai-center mb-20'}>
        <h2>Stores</h2>
        <Button type={'primary'} onClick={() => setIsCreateModalOpen(true)}>
          Create New Store
        </Button>
      </div>
      <SkeletonTable
        columns={StoresTableColumns(() => void 0) as SkeletonTableColumnsType[]}
        loading={isLoading}>
        <Table
          bordered={true}
          dataSource={data?.reverse()}
          columns={StoresTableColumns(handleUpdateActivation)}
        />
      </SkeletonTable>
      <CreateStoreModal
        isOpen={isCreateModalOpen}
        handleClose={() => setIsCreateModalOpen(false)}
        handleSuccessCreate={refetchStores}
      />
      <StoreActivationModal
        store={selectedStore}
        isOpen={isActivationModalOpen}
        handleClose={() => setIsActivationModalOpen(false)}
        handleSuccessActivation={refetchStores}
      />
    </div>
  );
};
export default StoresPage;
