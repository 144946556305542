import {Errors} from '../constants/errors';
import {toast} from 'react-toastify';

export const handleLoginError = (error: string): void => {
  if (error === Errors.UserNotFound) {
    toast.error('User not found');
    return;
  }
  if (error === Errors.UserPasswordIncorrect) {
    toast.error('Password is incorrect');
    return;
  }
  if (error === Errors.UserNotAcceptableRole) {
    toast.error(
      "This account is connected to one of mobile apps. To log in, you'll need to use the appropriate app",
    );
  }
};
